import React from 'react';
import { graphql, Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Layout } from '@components';
import { IconBookmark } from '@components/icons';
import './snackmovie.css';

const StyledMainContainer = styled.main`
  & > header {
    margin-bottom: 100px;
    text-align: center;

    a {
      &:hover,
      &:focus {
        cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>⚡</text></svg>")
            20 0,
          auto;
      }
    }
  }

  footer {
    ${({ theme }) => theme.mixins.flexBetween};
    width: 100%;
    margin-top: 20px;
  }
`;
const StyledGrid = styled.ul`
  ${({ theme }) => theme.mixins.resetList};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  margin-top: 50px;
  position: relative;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;
const StyledPost = styled.li`
  transition: var(--transition);
  cursor: default;

  @media (prefers-reduced-motion: no-preference) {
    &:hover,
    &:focus-within {
      .post__inner {
        transform: translateY(-7px);
      }
    }
  }

  a {
    position: relative;
    z-index: 1;
  }

  .post__inner {
    ${({ theme }) => theme.mixins.boxShadow};
    ${({ theme }) => theme.mixins.flexBetween};
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    border-radius: var(--border-radius);
    transition: var(--transition);
    background-color: var(--light-navy);

    header,
    a {
      width: 100%;
    }
  }

  .post__icon {
    ${({ theme }) => theme.mixins.flexBetween};
    color: var(--green);
    margin-bottom: 30px;
    margin-left: -5px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .post__title {
    margin: 0 0 10px;
    color: var(--lightest-slate);
    font-size: var(--fz-xxl);

    a {
      position: static;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .post__desc {
    color: var(--light-slate);
    font-size: 17px;
  }

  .post__date {
    color: var(--light-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xxs);
    text-transform: uppercase;
  }

  ul.post__tags {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      color: var(--green);
      font-family: var(--font-mono);
      font-size: var(--fz-xxs);
      line-height: 1.75;

      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }
`;

const PensievePage = ({ location, data }) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout location={location}>
      <Helmet title="Wire Frames" />

      <StyledMainContainer>
        <header>
          <h1 className="big-heading">Snack Movie</h1>
          <p className="divcolor">
            To design a responsive web app where the user can order snacks  in a theater.
          </p>
          <StaticImage
            className="imgxsa"
            src="../../images/test2.png"
            quality={100}
            width={1000}
            height={920}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Headshot"
          />
        </header>
        <div className="">
          <h3>PROCESS</h3>
        </div>
        <StaticImage
          className="imgxsa"
          src="../../images/processsnack.png"
          quality={100}
          layout="fullWidth"
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Headshot"
        />
        <div className="header-space-above-margin ">
          <h3>OUR PROBLEM</h3>
          <p className="divcolor ">
            People who are old and people of all age need to wait a long time to grab their snacks
            in a theater during intervals and it gets delayed due to rush and payment facilities as
            well as the snack options are limited.
          </p>
          <p className=" ">
            How might we design an interface that is unique,but still connects to the users that is
            compact enough to give users focus on the core problem ?
          </p>
        </div>

        <div className="header-space-above-margin ">
          <h3 className="header-space-above-padding ">BRAIN STORMING</h3>
          <p className="divcolor ">
            To understand the various concepts and design process and the procedure to follow
            brainstorming sessions were the most useful and we covered points about-
          </p>
          <p className=" ">Consider every angle with SCAMPER visualization</p>
          <p className=" ">Put on the right thinking hat</p>
          <StaticImage
            className=""
            src="../../images/brainstorming.jpg"
            width={1000}
            quality={100}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Headshot"
          />
          <p className=" header-space-above-padding ">Explore the potential of mind mapping</p>
          <p className=" ">Leverage the power of groups</p>
          <p className=" ">Get strategic with a SWOT analysis</p>
        </div>
        <div className="header-space-above-margin ">
          <h3>SNACK MOVIE APP-RESPONSIVE</h3>
          <p className="divcolor ">
            From the pre-guidlines and decisions,Responsive app with Accessibility was the integral
            part of the design. Typically, a responsive app has had its layout tuned for the
            available screen size. This means re-laying out the UI if the user resizes the window,
            or changes the device’s orientation. This is especially necessary when the same app can
            run on a variety of devices, from a watch, phone, tablet, to a laptop or desktop
            computer.
          </p>
        </div>
        <h4 className="header-space-below-padding header-space-above-margin">Desktop</h4>
        <p className="divcolor">Desktop view of Snack-Movie App</p>
        <StaticImage
          className=""
          src="../../images/lap.jpeg"
          width={1000}
          height={920}
          quality={100}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Headshot"
        />
        <h4 className="header-space-below-padding header-space-above-margin">Mobile</h4>
        <p className="divcolor">Mobile view of Snack-Movie App</p>
        <StaticImage
          className=""
          src="../../images/mobile.jpeg"
          width={1000}
          height={920}
          quality={100}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Headshot"
        />
        <p className="header-space-above-padding ">
          This app provides the users to easily order their snacks within a theater or outside as
          per their convenience and time.
        </p>

        <div className="divcolor header-space-above-margin ">
          <p>
            The various stages of design process from the starting stage to the final design which
            include personas,problem statement,insights,usability studies,low fidelity wireframe and
            prototypes, high fidelity prototypes and mockups are classified and listed below.
          </p>
        </div>
        <StyledGrid>
          {posts.length > 0 &&
            posts.map(({ node }, i) => {
              const { frontmatter } = node;
              const { title, description, slug, date, tags } = frontmatter;
              const formattedDate = new Date(date).toLocaleDateString();

              return (
                <StyledPost key={i}>
                  <div className="post__inner">
                    <header>
                      <div className="post__icon">
                        <IconBookmark />
                      </div>
                      <h5 className="post__title">
                        <Link to={slug}>{title}</Link>
                      </h5>
                      <p className="post__desc">{description}</p>
                    </header>

                    <footer>
                      <span className="post__date">{formattedDate}</span>
                    </footer>
                  </div>
                </StyledPost>
              );
            })}
        </StyledGrid>
        <h3 className="header-space-above-margin">Final Thoughts</h3>
        <p className="divcolor header-space-below-padding">Many proud moments</p>
        <div className="  ">
          <p>
            The final pitch to our clients was a packed house, including our clients. Our clients were exuberant after seeing the
            prototype and especially pleased with the way we had stayed true to their branding. They
            also thanked our team handsomely. The prototype was also chosen to
            be developed by the App Development Team. As a result, I managed the hand-off and
            collaborated with the developers to bring the project to life.
          </p>
        </div>
        <StaticImage
          className="imgxsa"
          src="../../images/finalsnack.jpg"
          width={1000}
          quality={100}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Headshot"
        />
        <div className="header-space-above-padding">
          <p>
          "Absolutely love it. I'm speechless. It's on-brand but completely new. The deep thought and detail you all went into is really amazing."
          </p>
        </div>
        <p className="divcolor">Chris Rock,Co-Founder</p>
      </StyledMainContainer>
    </Layout>
  );
};

PensievePage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default PensievePage;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/posts/snackmovie/" }
        frontmatter: { draft: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            slug
            date
            tags
            draft
          }
          html
        }
      }
    }
  }
`;
